export function getSystemKeyInfoList({ commit }, data) {
  const opts = {
    method: 'post',
    url: `api/systemkey/list`,
    data,
  };
  const headers = {
    'Content-Type': 'multipart/form-data',
    'X-FRAME-OPTIONS': 'SAMEORIGIN',
    'Content-Security-Policy':
      "default 'self';script-src 'self'; connect-src 'self'; img-src 'self'; style-src 'self';frame-ancestors 'self'",
    'Strict-Transport-Security':
      '"max-age=31536000; includeSubDomains; preload"',
    'Access-Control-Allow-Origin': '*',
  };

  return this.$axios(opts, commit, headers);
}

export function getSystemKeyFileList({ commit }, data) {
  const opts = {
    method: 'post',
    url: `api/systemkey/detail`,
    data,
  };
  const headers = {
    'Content-Type': 'multipart/form-data',
    'X-FRAME-OPTIONS': 'SAMEORIGIN',
    'Content-Security-Policy':
      "default 'self';script-src 'self'; connect-src 'self'; img-src 'self'; style-src 'self';frame-ancestors 'self'",
    'Strict-Transport-Security':
      '"max-age=31536000; includeSubDomains; preload"',
    'Access-Control-Allow-Origin': '*',
  };

  return this.$axios(opts, commit, headers);
}

export function createNewSystemKey({ commit }, data) {
  const opts = {
    method: 'post',
    url: `api/systemkey/createprivatekey`,
    data,
  };
  const headers = {
    'Content-Type': 'multipart/form-data',
    'X-FRAME-OPTIONS': 'SAMEORIGIN',
    'Content-Security-Policy':
      "default 'self';script-src 'self'; connect-src 'self'; img-src 'self'; style-src 'self';frame-ancestors 'self'",
    'Strict-Transport-Security':
      '"max-age=31536000; includeSubDomains; preload"',
    'Access-Control-Allow-Origin': '*',
  };

  return this.$axios(opts, commit, headers);
}

export function checkMemberFileCount({ commit }, data) {
  const opts = {
    method: 'post',
    url: `api/member/checkfilecount`,
    data,
  };
  const headers = {
    'Content-Type': 'multipart/form-data',
    'X-FRAME-OPTIONS': 'SAMEORIGIN',
    'Content-Security-Policy':
      "default 'self';script-src 'self'; connect-src 'self'; img-src 'self'; style-src 'self';frame-ancestors 'self'",
    'Strict-Transport-Security':
      '"max-age=31536000; includeSubDomains; preload"',
    'Access-Control-Allow-Origin': '*',
  };

  return this.$axios(opts, commit, headers);
}
