export function getOrganizationList({ commit }, data) {
  const opts = {
    method: 'post',
    url: `/api/organization/list`,
    data,
  };
  const headers = {
    'Content-Type': 'multipart/form-data',
    'X-FRAME-OPTIONS': 'SAMEORIGIN',
    'Content-Security-Policy':
      "default 'self';script-src 'self'; connect-src 'self'; img-src 'self'; style-src 'self';frame-ancestors 'self'",
    'Strict-Transport-Security':
      '"max-age=31536000; includeSubDomains; preload"',
    'Access-Control-Allow-Origin': '*',
  };

  return this.$axios(opts, commit, headers);
}

export function putOrganizationUpdate({ commit }, data) {
  const opts = {
    method: 'post',
    url: `/api/organization/update`,
    data,
  };
  const headers = {
    'Content-Type': 'multipart/form-data',
    'X-FRAME-OPTIONS': 'SAMEORIGIN',
    'Content-Security-Policy':
      "default 'self';script-src 'self'; connect-src 'self'; img-src 'self'; style-src 'self';frame-ancestors 'self'",
    'Strict-Transport-Security':
      '"max-age=31536000; includeSubDomains; preload"',
    'Access-Control-Allow-Origin': '*',
  };

  return this.$axios(opts, commit, headers);
}

export function postAddOrganization({ commit }, data) {
  const opts = {
    method: 'post',
    url: `/api/organization/add`,
    data,
  };
  const headers = {
    'Content-Type': 'multipart/form-data',
    'X-FRAME-OPTIONS': 'SAMEORIGIN',
    'Content-Security-Policy':
      "default 'self';script-src 'self'; connect-src 'self'; img-src 'self'; style-src 'self';frame-ancestors 'self'",
    'Strict-Transport-Security':
      '"max-age=31536000; includeSubDomains; preload"',
    'Access-Control-Allow-Origin': '*',
  };

  return this.$axios(opts, commit, headers);
}

export function getOrganizationMappingList({ commit }, data) {
  const opts = {
    method: 'post',
    url: `/api/organizationmappings/list`,
    data,
  };
  const headers = {
    'Content-Type': 'multipart/form-data',
    'X-FRAME-OPTIONS': 'SAMEORIGIN',
    'Content-Security-Policy':
      "default 'self';script-src 'self'; connect-src 'self'; img-src 'self'; style-src 'self';frame-ancestors 'self'",
    'Strict-Transport-Security':
      '"max-age=31536000; includeSubDomains; preload"',
    'Access-Control-Allow-Origin': '*',
  };

  return this.$axios(opts, commit, headers);
}

export function addOrganizationMapping({ commit }, data) {
  const opts = {
    method: 'post',
    url: `/api/organizationmappings/add`,
    data,
  };
  const headers = {
    'Content-Type': 'multipart/form-data',
    'X-FRAME-OPTIONS': 'SAMEORIGIN',
    'Content-Security-Policy':
      "default 'self';script-src 'self'; connect-src 'self'; img-src 'self'; style-src 'self';frame-ancestors 'self'",
    'Strict-Transport-Security':
      '"max-age=31536000; includeSubDomains; preload"',
    'Access-Control-Allow-Origin': '*',
  };

  return this.$axios(opts, commit, headers);
}
