export function getGroupList({ commit }, data) {
  const opts = {
    method: 'post',
    url: `api/group/list`,
    data,
  };
  const headers = {
    'Content-Type': 'multipart/form-data',
    'X-FRAME-OPTIONS': 'SAMEORIGIN',
    'Content-Security-Policy':
      "default 'self';script-src 'self'; connect-src 'self'; img-src 'self'; style-src 'self';frame-ancestors 'self'",
    'Strict-Transport-Security':
      '"max-age=31536000; includeSubDomains; preload"',
    'Access-Control-Allow-Origin': '*',
  };

  return this.$axios(opts, commit, headers);
}

export function postAddGroup({ commit }, data) {
  const opts = {
    method: 'post',
    url: `/api/group/add`,
    data,
  };
  const headers = {
    'Content-Type': 'multipart/form-data',
    'X-FRAME-OPTIONS': 'SAMEORIGIN',
    'Content-Security-Policy':
      "default 'self';script-src 'self'; connect-src 'self'; img-src 'self'; style-src 'self';frame-ancestors 'self'",
    'Strict-Transport-Security':
      '"max-age=31536000; includeSubDomains; preload"',
    'Access-Control-Allow-Origin': '*',
  };

  return this.$axios(opts, commit, headers);
}

export function postUpdateGroup({ commit }, data) {
  const opts = {
    method: 'post',
    url: `/api/group/update`,
    data,
  };
  const headers = {
    'Content-Type': 'multipart/form-data',
    'X-FRAME-OPTIONS': 'SAMEORIGIN',
    'Content-Security-Policy':
      "default 'self';script-src 'self'; connect-src 'self'; img-src 'self'; style-src 'self';frame-ancestors 'self'",
    'Strict-Transport-Security':
      '"max-age=31536000; includeSubDomains; preload"',
    'Access-Control-Allow-Origin': '*',
  };

  return this.$axios(opts, commit, headers);
}

export function getGroupByGroupNo({ commit }, data) {
  const opts = {
    method: 'post',
    url: `/api/group/info`,
    data,
  };
  const headers = {
    'Content-Type': 'multipart/form-data',
    'X-FRAME-OPTIONS': 'SAMEORIGIN',
    'Content-Security-Policy':
      "default 'self';script-src 'self'; connect-src 'self'; img-src 'self'; style-src 'self';frame-ancestors 'self'",
    'Strict-Transport-Security':
      '"max-age=31536000; includeSubDomains; preload"',
    'Access-Control-Allow-Origin': '*',
  };

  return this.$axios(opts, commit, headers);
}
